<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <van-cell-group inset >
            <van-form>
                <van-cell title="身份证正面">
                    <template #label>
                        <uploader-component
                            :farr="driver.icard_file_a"
                            url="car_driver/icard_file_a"
                            :max_count="1"
                            @update_img="update_icard_file_a"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="身份证反面">
                    <template #label>
                        <uploader-component
                            :farr="driver.icard_file_b"
                            url="car_driver/icard_file_b"
                            :max_count="1"
                            @update_img="update_icard_file_b"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="驾驶证主页">
                    <template #label>
                        <uploader-component
                            :farr="driver.jscard_file_a"
                            url="car_driver/jscard_file_a"
                            :max_count="1"
                            @update_img="update_jscard_file_a"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-cell title="驾驶证副页">
                    <template #label>
                        <uploader-component
                            :farr="driver.jscard_file_b"
                            url="car_driver/jscard_file_b"
                            :max_count="1"
                            @update_img="update_jscard_file_b"
                        ></uploader-component>
                    </template>
                </van-cell>
                <van-field
                        v-model="driver.yzm"
                        name="yzm"
                        label="验证码"
                        placeholder="请输入验证码"
                    >
                        <template #right-icon>
                            <van-button v-if="!sendPhoneTime" block type="info" size="small" @click="get_yzm">获取验证码</van-button>
                            <van-button v-else block type="info" size="small" disabled>重新获取({{ sendPhoneTime }})</van-button>
                        </template>
                    </van-field>
            </van-form>
            <div class="sub_button">
                <van-button round block type="primary" @click="sumbitCard">提 交</van-button>
            </div>
            <div class="sub_button">
                <van-button round block type="default" @click="back_details">返 回</van-button>
            </div>
        </van-cell-group>
    </div>

</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import UploaderComponent from '@/components/common/UploaderComponent.vue'

import { driver_information_request,driver_card_request,lf_card_yzm_request } from '@/network/user'

export default {
    name:'UserInformationComponent',
    data(){
        return {
            driver: {
                icard_file_a: [],
                icard_file_b: [],
                jscard_file_a: [],
                jscard_file_b: [],
                yzm: ''
            },
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
                fixed: false,   //固定
                placeholder: false  //等高占位
            },
            my_nav_bar_name: '修改证件照',

        }
    },
    computed:{},
    methods:{
        get_my_information() {
            this.$store.commit('true_loading')
            driver_information_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.driver = {
                            id: s.result.id,
                            icard_file_a: s.result.icard_file_a,
                            icard_file_b: s.result.icard_file_b,
                            jscard_file_a: s.result.jscard_file_a,
                            jscard_file_b: s.result.jscard_file_b,
                            yzm: ''
                        }
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        update_icard_file_a(arr) {
            this.driver.icard_file_a = arr
        },
        update_icard_file_b(arr) {
            this.driver.icard_file_b = arr
        },
        update_jscard_file_a(arr) {
            this.driver.jscard_file_a = arr
        },
        update_jscard_file_b(arr) {
            this.driver.jscard_file_b = arr
        },
        get_yzm() {
            this.$store.commit('true_loading')
            this.sendPhoneTime = 60
            lf_card_yzm_request()
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('验证码已发送')
                            const changeSendPhoneTime = setInterval(() => {
                                if (this.sendPhoneTime) {
                                    this.sendPhoneTime--
                                } else {
                                    clearInterval(changeSendPhoneTime)
                                }
                            }, 1000)
                        } else {
                            this.$toast.fail(s.msg)
                            this.sendPhoneTime = 0
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg)
                        this.sendPhoneTime = 0
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
        sumbitCard() {
            this.$store.commit('true_loading')
            driver_card_request(this.driver)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$toast.success('修改成功');
                            this.$router.replace('/UserInformation')
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => this.$store.commit('false_loading'))
        },
        back_details() {
            this.$router.go(-1);//返回上一层
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_my_information()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        NavBarComponent,
        LoadingComponent,
        UploaderComponent
    },
    watch:{}
}
</script>

<style>
.information_portrait {
    margin-top:0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
}
.information_portrait>img {
    width: 3rem;
    height: 3rem;
    border-radius:50%;
    object-fit: unset;
}
.sub_button {
    margin: 0.5rem;
}
</style>